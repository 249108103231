import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import ConfirmBox from '../Reusable/ConfirmBox'
import { sendCommand } from '../../helperFunctions/actions'

function timeSince(timestamp) {
  try {
    //it seems socket.io doesn't send a key if its value is null
    if(!timestamp) {
      throw new Error("ERROR: no problem timestamp")
    }
    return moment(timestamp).fromNow()
  } catch(e) {
    console.error(e)
    return "(received no time data)"
  }
}

function UserProblem(props) {
  const [timeSinceProblem, setTimeSinceProblem] = useState("");
  let { problem_timestamp } = props.room;
  const [responseText, setResponseText] = useState("");
  const [noteChanged, setNoteChanged] = useState(false);

  if (problem_timestamp === null) problem_timestamp = moment();

  // set interval for every second to update time since problem occurred
  useEffect(() => {
    //force an update by setting state
    let interval = setInterval(() => setTimeSinceProblem(timeSince(problem_timestamp)), 1000);
    return () => clearInterval(interval);
  }, [problem_timestamp]);

  useEffect(() => {
    setResponseText(props.room.prob_note);
    setNoteChanged(false);
  }, [props.room.prob_note]);


  function handleFormChange(e) {
    setNoteChanged(true);
    setResponseText(e.target.value);
  }
  function handleSubmit() {
    // Johnny (johndoe) : my problem-specific note
    const text = props.nickname + " (" + props.uniqname + ") : " + (responseText || "User removed problem-specific note");
    window.g_socket.emit("save_prob_note", props.room.id, text);
  }

  const [nextCommand, setNextCommand] = useState("");
  const [turnOffProblemReasonText, setTurnOffProblemReasonText] = useState("");
  const [techResUsername, setTechResUsername] = useState(props.nickname);

  function sendCommand() {
    console.log(props.room.id, turnOffProblemReasonText, techResUsername);
    if(nextCommand === "tech_response") {
      props.sendCommand("tech_response", { id: props.room.id, techResUsername} );
    } else {
      props.sendCommand("problem_off", { id: props.room.id, turnOffProblemReasonText});
    }
    setNextCommand("");
  }

  const { room } = props;
  return (
    <div>
      <div className="room-box">
        {/* Title */}
        <div className="row center-xs box-title greybg">
          <div className="col-xs-12">
            <h4>Help requested {timeSinceProblem}</h4>
          </div>
        </div>
        {/* Problem-specific note */}
        <div className="col-xs-12 center-xs">
          <textarea 
            className="command-controls-textarea"
            rows="3" 
            placeholder="Leave a temporary, problem-specific note" 
            onChange={handleFormChange}
            value={responseText}
          ></textarea>
          {noteChanged && <div className="button" onClick={handleSubmit}>
            Unsaved, Save Note
          </div>}
        </div>
        {/* Respond / {person} is responding button */}
        <div className="col-xs-12 center-xs" id="tech-res-button-container">
          {(room.tech_response === "") 
            ? <div className="button" onClick={()=>setNextCommand("tech_response")}>
                Respond to Problem
              </div>
            : <div className="button-disabled">
                {room.tech_response} is responding
              </div>}
        </div>
        
        {/* Turn off problem button */}
        <div className="col-xs-12 center-xs" id="problem-off-button-container">
          <div className="button" onClick={()=>setNextCommand("problem_off")}>
            {/* display a spinner if the problem is loading */}
            Turn Off User Problem
          </div>
        </div>
      </div>

      {/* Username input (if the Respond button clicked)*/}
      {(nextCommand === "tech_response") && 
          <ConfirmBox onConfirm={sendCommand} onCancel={() => setNextCommand("")} targetEl={document.getElementById("tech-res-button-container")}>
            <div className="col-xs-12 center-xs">
              <input
                className="command-controls-textarea"
                type="text"
                placeholder="Username"
                value={techResUsername}
                onChange={(e) => setTechResUsername(e.target.value)}
              ></input>
            </div>
          </ConfirmBox>
        }
        {/* Turn off problem reason input (if turn off button clicked) */}
        {(nextCommand === "problem_off") && 
          <ConfirmBox onConfirm={sendCommand} onCancel={() => setNextCommand("")} targetEl={document.getElementById("problem-off-button-container")}>
            <div className="col-xs-12 center-xs">
              <textarea 
                className="command-controls-textarea"
                rows="3" 
                placeholder="Reason for turning off this problem..."
                value={turnOffProblemReasonText}
                onChange={(e) => setTurnOffProblemReasonText(e.target.value)}
              ></textarea>
            </div>
          </ConfirmBox>
        }
    </div>
  )
}

function mapStateToProps(state) {
  return {
    uniqname: state.uniqname,
    nickname: state.nickname,
  }
}

const mapActionsToProps = { sendCommand };
export default connect(mapStateToProps, mapActionsToProps)(UserProblem)