import React, { useState, useEffect, useRef } from 'react';
import ModernDatepicker from 'react-modern-datepicker';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import './ImageQueryStyles.css';
import SnapWindow from './SnapWindow';
import formatDate from '../../helperFunctions/formatDate';
import moment from 'moment';
import { withRouter } from '../../helperFunctions/withRouter';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

const MAX_STORED_SNAPS = 720; // 2hrs at 6 snaps per minute

function RoomListOptions({ rooms }) {
  return rooms.map((room) => (
    <option key={room.id} value={room.name} />
  ));
}

function VideoImageQuery(props) {
  const [roomSnaps, setRoomSnaps] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment().format("MMMM DD, YYYY"));
  const [selectedTime, setSelectedTime] = useState(moment(new Date()));
  const [selectedRoom, setSelectedRoom] = useState("");
  const [rooms, setRooms] = useState([]);
  const [minTime, setMinTime] = useState(null);
  const [maxTime, setMaxTime] = useState(null);
  const [awaitingSnaps, setAwaitingSnaps] = useState({ waiting: false, location: '', room: null });
  const [gotInitialSnaps, setGotInitialSnaps] = useState(false);
  const [moreSnaps, setMoreSnaps] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [timepickerOpen, setTimepickerOpen] = useState(false);

  const navigate = useNavigate();

  // A ref to always have access to the current state values in our socket callbacks.
  const stateRef = useRef({ roomSnaps, awaitingSnaps, gotInitialSnaps, rooms, selectedRoom });
  useEffect(() => {
    stateRef.current = { roomSnaps, awaitingSnaps, gotInitialSnaps, rooms, selectedRoom };
  }, [roomSnaps, awaitingSnaps, gotInitialSnaps, rooms, selectedRoom]);

  const requestInitialSnaps = () => {
    if (awaitingSnaps.waiting) {
      setErrorMsg('Loading...');
      return;
    }
    const indexOfRoom = rooms.findIndex((room) => room.name === selectedRoom);
    if (indexOfRoom === -1) {
      setErrorMsg("That's an invalid room.");
      return;
    } else if (!selectedDate || !selectedTime) {
      setErrorMsg("Please enter a time.");
      return;
    }
    setGotInitialSnaps(false);

    let newTime = new Date(selectedDate);
    const selectedTimeDate = new Date(selectedTime);
    newTime.setHours(newTime.getHours() + selectedTimeDate.getHours());
    newTime.setMinutes(newTime.getMinutes() + selectedTimeDate.getMinutes());

    const MINUTES_PADDING = 30;
    window.g_socket.emit('get-ip-image-urls', {
      time: newTime,
      roomId: rooms[indexOfRoom].id,
      duration: MINUTES_PADDING * 60,
    });
    setAwaitingSnaps({ waiting: true, location: 'initial', room: rooms[indexOfRoom].id });
  };

  const requestMoreSnaps = (location) => {
    if (awaitingSnaps.waiting) {
      if (errorMsg !== "Loading...") setErrorMsg('Loading...');
      return;
    }
    const ONE_MINUTE = 60000; // milliseconds
    const MINUTES_PADDING = 5;
    let newTime;
    if (location === 'start') {
      newTime = new Date(minTime.getTime() - ONE_MINUTE * MINUTES_PADDING);
    } else if (location === 'end') {
      newTime = new Date(maxTime.getTime() + ONE_MINUTE * MINUTES_PADDING);
    }
    window.g_socket.emit('get-ip-image-urls', {
      time: newTime,
      roomId: awaitingSnaps.room,
      duration: MINUTES_PADDING * 60,
    });
    setAwaitingSnaps((prev) => ({ ...prev, waiting: true, location }));
  };

  const handleGoButtonClick = () => {
    requestInitialSnaps();
  };

  const goToGridView = () => {
    // Tooltip.hide();
    navigate("/viewimages/grid");
  };

  const datepickerChanged = (val) => {
    if (val !== "Invalid Date") {
      setSelectedDate(val);
      setTimepickerOpen(true);
    }
  };

  useEffect(() => {
    // Request the room list when the component mounts.
    window.g_socket.emit('get-image-roomlist');

    const ipImageUrlsHandler = (data) => {
      let newSnaps = [];
      let starterKey;
      switch (stateRef.current.awaitingSnaps.location) {
        case 'start':
          starterKey = stateRef.current.roomSnaps[0].key - data.snapList.length;
          break;
        case 'end':
          starterKey = stateRef.current.roomSnaps[stateRef.current.roomSnaps.length - 1].key + 1;
          break;
        case 'initial':
          starterKey = 0;
          break;
        default:
          break;
      }
      for (let i = 0; i < data.snapList.length; i++) {
        const snap = data.snapList[i];
        const time = snap.time;
        const url = `https://s3.amazonaws.com/caenview-prod/${snap.filename}`;
        const key = starterKey + i;
        // Preload image
        new Image().src = url;
        newSnaps.push({ time, url, key });
      }

      let newRoomSnaps, newMinTime, newMaxTime, newGotInitialSnaps, newMoreSnaps, newErrorMsg;
      newErrorMsg = "";
      if (newSnaps.length > 0) {
        if (stateRef.current.awaitingSnaps.location !== 'initial') {
          const oldSnaps = stateRef.current.roomSnaps;
          if (stateRef.current.awaitingSnaps.location === 'start') {
            if (oldSnaps.length + newSnaps.length > MAX_STORED_SNAPS) {
              newRoomSnaps = newSnaps.concat(oldSnaps.slice(0, oldSnaps.length - newSnaps.length));
            } else {
              newRoomSnaps = newSnaps.concat(oldSnaps);
            }
          } else if (stateRef.current.awaitingSnaps.location === 'end') {
            if (oldSnaps.length + newSnaps.length > MAX_STORED_SNAPS) {
              newRoomSnaps = oldSnaps.slice(newSnaps.length).concat(newSnaps);
            } else {
              newRoomSnaps = oldSnaps.concat(newSnaps);
            }
          }
          newMinTime = new Date(newRoomSnaps[0].time);
          newMaxTime = new Date(newRoomSnaps[newRoomSnaps.length - 1].time);
          newGotInitialSnaps = stateRef.current.gotInitialSnaps;
        } else {
          newRoomSnaps = newSnaps;
          newMinTime = new Date(newSnaps[0].time);
          newMaxTime = new Date(newSnaps[newSnaps.length - 1].time);
          newGotInitialSnaps = true;
        }
        newMoreSnaps = true;
      } else {
        newMoreSnaps = false;
        newErrorMsg = "We couldn't find any " + (stateRef.current.gotInitialSnaps ? "" : "more ") + "snaps in that range.";
      }
      setAwaitingSnaps((prev) => ({ ...prev, waiting: false, location: '' }));
      setErrorMsg(newErrorMsg);
      setRoomSnaps(newRoomSnaps);
      setMinTime(newMinTime);
      setMaxTime(newMaxTime);
      setGotInitialSnaps(newGotInitialSnaps);
      setMoreSnaps(newMoreSnaps);
    };

    const imageRoomlistHandler = (data) => {
      setRooms(data);
      if (props.location && props.location.state) {
        const { roomName, time } = props.location.state;
        if (
          data.some(room => room.name === roomName) &&
          time instanceof Date &&
          !isNaN(time.getTime())
        ) {
          setSelectedRoom(roomName);
          setSelectedDate(moment(time).format("MMMM DD, YYYY"));
          setSelectedTime(moment(time));
          requestInitialSnaps();
        } else {
          console.error("Malformed location state to jump to time:", props.location.state);
        }
      }
    };

    window.g_socket.on('ip-image-urls', ipImageUrlsHandler);
    window.g_socket.on('image-roomlist', imageRoomlistHandler);

    return () => {
      window.g_socket.removeAllListeners('ip-image-urls');
      window.g_socket.removeAllListeners('image-roomlist');
    };
  }, [props.location]);

  const currentRoom = rooms.find(room => room.id === awaitingSnaps.room);

  return (
    <div className="rooms-container single-view">
      <div className="row between-xs">
        <div className="col-xs-3" id="left-column-imagequery">
          <div className="imagequery-top-box">
            {awaitingSnaps.room ? (
              <h1 className="imagequery-room-title">
                {currentRoom ? currentRoom.name : ''}
              </h1>
            ) : (
              <h1>View Room Snaps</h1>
            )}
            {gotInitialSnaps && (
              <div>
                <h2>{formatDate(minTime)} to </h2>
                <br />
                <h2>{formatDate(maxTime)}</h2>
              </div>
            )}
            {rooms.length !== 0 && (
              <div>
                <input
                  autoFocus
                  className="text-input"
                  type="text"
                  list="imagequery-room-list"
                  value={selectedRoom}
                  onChange={(e) => setSelectedRoom(e.target.value)}
                />
                <datalist id="imagequery-room-list">
                  <RoomListOptions rooms={rooms} />
                </datalist>
              </div>
            )}
          </div>
          <div className="imagequery-middle-box">
            <h2>Date and Time:</h2>
            <div className="date-time-pickers-container">
              <div className="datepicker">
                <ModernDatepicker
                  date={selectedDate}
                  onChange={datepickerChanged}
                  placeholder="Select a date..."
                  format="MMMM DD, YYYY"
                  primaryColor="var(--a-main-bg)"
                  secondaryColor="var(--main-bg)"
                  primaryTextColor="var(--info-text)"
                  secondaryTextColor="var(--a-info-text)"
                />
              </div>
              <div className="timepicker">
                <TimePicker
                  value={selectedTime}
                  onChange={(val) => setSelectedTime(val)}
                  open={timepickerOpen}
                  onOpen={() => setTimepickerOpen(true)}
                  onClose={() => setTimepickerOpen(false)}
                  showSecond={false}
                  format="h:mm a"
                  use12Hours
                  inputReadOnly
                  onAmPmChange={() => setTimepickerOpen(false)}
                />
              </div>
            </div>
            {selectedDate &&
              selectedTime &&
              rooms.some((room) => room.name === selectedRoom) && (
                <div className="button imagequery-go-button fade-in" onClick={handleGoButtonClick}>
                  Go!
                </div>
              )}
            <div className="error-msg">
              <h2>{errorMsg}</h2>
            </div>
          </div>
          <div className="imagequery-bottom-box">
            <h2>View:</h2>
            <div className="row view-container">
              <div
                className="button col-sm center-xs active-view-button"
                data-tip="View snaps as a video feed"
              >
                <i className="fa fa-video"></i>
              </div>
              &nbsp;
              <div className="button col-sm center-xs" onClick={goToGridView} data-tip="View snaps as a grid of snaps">
                <i className="fa fa-th"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-9" id="right-column">
          {gotInitialSnaps && roomSnaps.length > 0 && (
            <SnapWindow
              roomSnaps={roomSnaps}
              requestMoreSnaps={requestMoreSnaps}
              sliderConfig={{ min: roomSnaps[0].key, max: roomSnaps[roomSnaps.length - 1].key }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default withRouter(VideoImageQuery);