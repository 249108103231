// Assumption for fmcrb 1060
// sources: Laptop 1, Laptop 2, Document Camera, Apple TV, Zoom PC, Atrium TV, Atrium Camera
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { setReduxState } from '../../helperFunctions/actions';
import ReactModal from 'react-modal';
import Draggable from 'react-draggable';
import Slider from 'rc-slider';
import { Tooltip } from 'react-tooltip';
import 'rc-slider/assets/index.css';
import './RoomControl.css';

// Question for Phil + John
// Should we make the akg base station connect to caenctrl rather than having a direct connection to the crestron server.

// Show hide controls based on whether a user wants to change current state back to buttons
// Two level reset actions show/hide 
// Apple Tv Reset put under the rest.
// Possible timeout if the thing needs reset.
// Up and down and indicator of current value
// Up philview version and or if im recieving sources
// checkout why the projector hours are not showing.

// Flip to switching use a drop down use a box with set/reset on vol sliders.

// 2 parts
// control panel
// display data
//  reading from ping status instead of columns
//  use the buttons off
//  knowing the status

const RoomControl = ({ room, darkMode, titleColor, blocked }) => {
    const [modalOpen, updateModalOpen] = useState(false);
    const nodeRef = useRef();

    const closeModal = () => {
        updateModalOpen(false);
    }

    const resetFunc = (event) => {
        window.g_socket.emit("rc-action", {
            id: room.id,
            action: "reset",
            payload: event.target.name
        });
    }

    const sliders = [];

    if (room.ping_status && room.ping_status.room_info.volume) {
        for (let i = 0; i < Object.values(room.ping_status.room_info.volume).length; i++) {
            sliders.push(Object.values(room.ping_status.room_info.volume)[i]);
        }
    }
    return (
        <>
            <i className="fas fa-cog rc" style={{ pointerEvents: "auto" }} onClick={() => { modalOpen ? updateModalOpen(false) : updateModalOpen(true) }} />
            <ReactModal
                isOpen={modalOpen}
                onRequestClose={closeModal}
                className="roomControlModal"
                overlayClassName="roomControlModalOverlay"
                parentSelector={() => document.body}
                appElement={document.body}
            >
                <>
                    <Draggable cancel="div.box-contents" nodeRef={nodeRef}>
                        <div className="box-container" ref={nodeRef}>
                            <div className="room-box rcs">
                                <div className="row center-xs box-title greybg">
                                    <div className="col-xs-11 right">
                                        <h4 className="roomControlTitle">Room Control</h4>
                                    </div>
                                    <div className="col-xs-1">
                                        <i className="fa-solid fa-times" onClick={() => updateModalOpen(false)}></i>
                                    </div>
                                </div>
                                <div className="row box-contents">
                                    <div className="col-xs-12 center"> Under Construction.</div>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </>
            </ReactModal>
        </>
    )
}
const mapStateToProps = (state, ownProps) => {
    if (state.dataReceived) {
        return {
            room: state.rooms[ownProps.roomid],
            darkMode: state.darkMode,
            titleColor: ownProps.titleColor,
            blocked: false
        }
    } else {
        return {
            blocked: true
        }
    }
}


export default connect(mapStateToProps)(RoomControl);