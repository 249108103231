import React, { useState, useEffect } from 'react';
import ConfirmBox from '../Reusable/ConfirmBox';
import { connect } from 'react-redux';
import { sendCommand } from '../../helperFunctions/actions';

const Projectors = ({ room, sendCommand }) => {
  const [nextCommand, setNextCommand] = useState("");
  const [loading, setLoading] = useState({}); // keyed by classic_name
  const [selectedEl, setSelectedEl] = useState(null);

  // When a command is confirmed, send it and mark that projector as loading.
  const handleSendCommand = () => {
    sendCommand(nextCommand, { id: room.id });
    // Extract the classic_name from the command (e.g. "proj1" from "proj1_pwrOn")
    const [classicName] = nextCommand.split('_');
    setLoading(prev => ({ ...prev, [classicName]: nextCommand }));
    setNextCommand("");
  };

  // Build the command string based on the current state of the projector.
  const readyCommand = (ldKey, e) => {
    const ldisplay = room.ping_status.logical_displays[ldKey];
    if (!ldisplay) return;
    const command = `${ldisplay.classic_name}_pwr${ldisplay.is_on ? "Off" : "On"}`;
    setNextCommand(command);
    setSelectedEl(e.currentTarget);
  };

  // Effect that clears the loading flag when the projector's state matches the command.
  useEffect(() => {
    setLoading(prevLoading => {
      const updatedLoading = { ...prevLoading };
      const logicalDisplays = room.ping_status.logical_displays || {};
      Object.entries(logicalDisplays).forEach(([_, ldisplay]) => {
        if (ldisplay.type === "projector" && updatedLoading[ldisplay.classic_name] !== undefined) {
          // For a command ending in "pwrOn", we expect the projector's is_on to be true.
          const shouldBeOn = updatedLoading[ldisplay.classic_name].endsWith("pwrOn");

          // console.log(`Checking ${ldisplay.classic_name}: is_on=${ldisplay.is_on}, shouldBeOn=${shouldBeOn}`);
          if (ldisplay.is_on == shouldBeOn) {
            delete updatedLoading[ldisplay.classic_name];
          }
        }
      });
      return updatedLoading;
    });
  }, [JSON.stringify(room.ping_status.logical_displays)]);

  const connected = room.connected;
  const logicalDisplays = room?.ping_status?.logical_displays ?? {};
  const buttonClassName = connected ? "button" : "button-disabled";
  const loadingIconStyle = { fontSize: '15px', marginTop: '0px' };

  // Get only projector displays.
  const projectors = Object.entries(logicalDisplays)
    .filter(([_, display]) => display.type === "projector" && display.classic_name)
    .sort((a, b) => a[1].name.localeCompare(b[1].name));

  if (projectors.length === 0) return null;

  return (
    <div className="projectors">
      <div className={connected ? "room-box" : "room-box box-disabled"}>
        <div className="row center-xs box-title greybg">
          <div className="col-xs-12">
            <h4>Projector{projectors.length > 1 ? 's' : ''}</h4>
          </div>
        </div>
        {projectors.map(([ldKey, ldisplay]) => {
          return (
          <div key={ldKey} className="row box-contents">
            <div className="col-xs-1 center-xs">
              <i className={ldisplay.is_on ? 'fa fa-power-off green' : 'fa fa-power-off grey'}></i>
            </div>
            <div className="col-xs-11 center-xs">
              {ldisplay?.classic_name ? (
                <div className={buttonClassName} onClick={(e) => readyCommand(ldKey, e)}>
                  Turn {ldisplay.name} {!ldisplay.name.toLowerCase().includes('projector') && " Projector"} {ldisplay.is_on ? 'Off' : 'On'}
                  {loading[ldisplay.classic_name] && (
                    <i
                      className="fa fa-spinner fa-pulse fa-3x fa-fw"
                      style={loadingIconStyle}
                    ></i>
                  )}
                </div>
              ) : (
                <>New button To be done</>
              )}
            </div>
          </div>
        );

      })}
      </div>
      {nextCommand && (
        <ConfirmBox
          onConfirm={handleSendCommand}
          onCancel={() => setNextCommand("")}
          targetEl={selectedEl}
        />
      )}
    </div>
  );
};

const mapActionsToProps = { sendCommand };
export default connect(null, mapActionsToProps)(Projectors);