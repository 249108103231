import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { setModal } from '../../helperFunctions/actions';
import VideoView from './VideoView';
import GridView from './GridView';


function ImageQuery(props) {
    const navigate = useNavigate();

    const { canViewImagequery, setModal } = props;
    useEffect(() => {
        console.log("imagequery");
        if (!canViewImagequery) {
            setModal({
                shown: true,
                header: "You're not authorized to view classroom snaps",
                paragraph: "If you think this is a mistake, please speak with CAEN administrative staff.",
                buttonText: "Back to CAENView",
                onClose: () => navigate('/')
            });
        }
        // eslint-disable-next-line
    }, [canViewImagequery, setModal]);


    if (!canViewImagequery) {
        return null;
    } else {
        return (
            <Routes>
                <Route path="/grid" element={<GridView/>}/>
                <Route path="/video" element={<VideoView/>} />
                <Route path="*" element={<Navigate to="/viewimages/video" />} />
            </Routes>
        )
    }
}

const mapStateToProps = (state) => ({ canViewImagequery: state.canViewImagequery });
const mapDispatchToActions = { setModal };
export default connect(mapStateToProps, mapDispatchToActions)(ImageQuery);