import React from 'react'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import interactionPlugin from '@fullcalendar/interaction'

//custom styles detailed in webapp/src/components/roominfo/calendarCustomStyle.css

class CalendarGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventSources: [],
      resources: [],
      dataReceived: false
    }
  }

  componentDidMount() {
      

    window.g_socket.emit('get_calendar_grid_resource_list');

    window.g_socket.on('calendar_grid_resource_list', (resources) => {

      this.setState( { resources:resources, dataReceived:false } )


      if (this.state.eventSources.length === 0) {
          // Only load this data once
          window.g_socket.emit('get_calendar_grid_resource_data');
      }
    })


    window.g_socket.on('calendar_grid_resource_data', (events) => {
      this.setState((prev) => {
        // because of FullCalender's memoization, we use ... syntax to make a new array object and force an update
        return { eventSources: [events, ...prev.eventSources], dataReceived: true }
      })
    });
  }
  componentWillUnmount() {
    window.g_socket.removeAllListeners('calendar_grid_resource_data');
    window.g_socket.removeAllListeners('calendar_grid_resource_list');
  }
  
  calendarComponentRef = React.createRef();

  render() {
    const { dataReceived, eventSources, resources } = this.state;

    if(!dataReceived) return  <div className="rooms-container"><h2>Loading...</h2></div>;

    return (
      <div className="rooms-container calendar-grid">
        <FullCalendar
        plugins={[resourceTimelinePlugin, interactionPlugin, dayGridPlugin]}
        timeZone="local"
        schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
        nowIndicator={true}
        headerToolbar={ {
            left: "today prev,next",
            center: "title",
            right: "timelineFifteen,resourceTimelineDay,resourceTimelineWeek"
        } }
        initialView="timelineFifteen"
        resourceAreaWidth="20%"
        resourcesInitiallyExpanded={false}
        slotMinTime="06:00:00"
        slotMaxTime="22:00:00"
        scrollTime="08:00:00"
        height="auto"
        views={ {
            timelineFifteen: {
                buttonText: ":15 slots",
                type: "resourceTimeline",
                slotDuration: "00:15"
            }
        } }
        resources={resources}
        eventSources={eventSources}
        ref={this.calendarComponentRef} 
        resourceAreaHeaderContent="Room"
        id="fcman"
      />
      </div>
    )
  }
}

export default CalendarGrid