import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import RoomControl from './RoomControl';
import 'reactjs-popup/dist/index.css';
import './RoomboxStyles.css';

const errors_by_category = (errors) => {
  const result = {};
  Object.keys(errors).forEach((key) => {
    const category = errors[key].category;
    if (!result[category]) {
      result[category] = {};
    }
    result[category][key] = errors[key];
  })

  return result;
}

function AVErrorRows({ room, darkMode }) {
  let res = [];
  const [state, setState] = useState(false);
  // room id 
  if (room.ping_status && room.ping_status.health && !room.ping_status.healthy) {
    const sorted_errors = errors_by_category(room.ping_status.health.errors);
    if (Object.keys(sorted_errors).length > 1) {
      res.push(
        <div className="row box-contents orangebg-lt">
          <div className="col-xs-11">Multiple Errors</div>
          <div className="col-xs-1">
            {!state ? <i className="fa-solid fa-caret-down" onClick={() => { setState(true) }}></i> : <i className="fa-solid fa-caret-up" onClick={() => { setState(false) }}></i>}
          </div>
        </div>
      )
    }
    Object.entries(sorted_errors).sort().forEach(([key, value]) => {
      if (Object.keys(sorted_errors).length > 1) {
        if (state) {
          res.push(<div className="row box-contents orangebg-lt">
            <div className="col-xs-11">{key}</div>
            <div className="col-xs-1">
              <Popup key={room.id + " " + key} trigger={<i className="fa-solid fa-circle-info"></i>} keepTooltipInside={true} className={darkMode ? "darkmode-popup" : ""}>
                <div className="row center-xs box-title around-xs ">
                  <div className="col-xs-12 room-box-title-container">
                    Errors:
                  </div>
                </div>
                <div className={"row box-contents left-xs"} style={{ whiteSpace: 'normal' }}>
                  {
                    Object.values(value).map((error_value) => {
                      return <div className="col-xs-12">{error_value['description']}</div>
                    })
                  }
                </div>
              </Popup>
            </div>
          </div>);
        }
      } else {
        res.push(<div className="row box-contents orangebg-lt">
          <div className="col-xs-11">{key}</div>
          <div className="col-xs-1">
            <Popup key={room.id + " " + key} trigger={<i className="fa-solid fa-circle-info"></i>} keepTooltipInside={true} className={darkMode ? "darkmode-popup" : ""}>
              <div className="row center-xs box-title around-xs ">
                <div className="col-xs-12 room-box-title-container">
                  Errors:
                </div>
              </div>
              <div className={"row box-contents left-xs"} style={{ whiteSpace: 'normal' }}>
                {
                  Object.values(value).map((error_value) => {
                    return <div className="col-xs-12">{error_value['description']}</div>
                  })
                }
              </div>
            </Popup>
          </div>
        </div>);
      }
    })
  }


  if (res.length > 0) {
    return res;
  }

  return null;
}

// Microphone data about charging bases with 0 - 2 rows
function MicRows({ room, darkMode }) {

  // AKG stuff needs ping status to be added

  //if no mics installed, return nothing.
  if (!room.akg_base_installed) return null;

  let mic_data = null;
  if (room.akg_base_data) {
    mic_data = JSON.parse(room.akg_base_data);
  }

  let number_mics_expected = 2;
  if (mic_data && mic_data.Device && mic_data.Device.number_mics_expected) {
    number_mics_expected = mic_data.Device.number_mics_expected;
  }

  let res = [];
  if (room.akg_connected && mic_data && mic_data.Device && mic_data.Slots && (number_mics_expected > 0) && mic_data.Device) {
    let chargingIcons = mic_data.Slots.map((slot, i) => {
      let slot_content = <div></div>
      if (slot.batt_state.readabe !== 'Empty') {
        slot_content = <table className='mic-details'>
          <caption>{
            room.ping_status !== null ? 'config_name' in room.ping_status.room_info ? room.ping_status.room_info.config_name : room.name :
              room.name
          }</caption>
          <tbody>
            <tr>
              <th>State:</th>
              <td>{slot.batt_state.readable}</td>
            </tr>
            <tr>
              <th>Charge:</th>
              <td>{slot.batt_charge.readable}</td>
            </tr>
            <tr>
              <th>Current Capacity:</th>
              <td>{slot.batt_current_capacity.readable}</td>
            </tr>
            <tr>
              <th>Current Capacity Max:</th>
              <td>{slot.batt_current_capacity_max.readable}</td>
            </tr>
            <tr>
              <th>Battery Capacity:</th>
              <td>{slot.batt_capacity_max.readable}</td>
            </tr>
            <tr>
              <th>Health:</th>
              <td>{slot.batt_health.readable}</td>
            </tr>
            <tr>
              <th>Cycles:</th>
              <td>{slot.batt_cycle.readable}</td>
            </tr>
            <tr>
              <th>Error:</th>
              <td>{slot.batt_error.readable}</td>
            </tr>
            <tr>
              <th>Temperature:</th>
              <td>{slot.batt_temp_f.readable}</td>
            </tr>
            <tr>
              <th>Time to Full:</th>
              <td>{slot.batt_time_to_full.readable}</td>
            </tr>
            <tr>
              <th>Firmware Version:</th>
              <td>{mic_data.Device.fw_ver.readable}</td>
            </tr>
            <tr>
              <th>Model:</th>
              <td>{mic_data.Device.model.readable}</td>
            </tr>
          </tbody>
        </table>
      }

      let icon = <div></div>;
      switch (slot.batt_state.readable) {
        case 'Empty':
          icon = <i key={i} className="fa fa-circle" data-tooltip-id="tooltip" data-tooltip-content="Empty"></i>;
          return icon;
        case 'Charged':
          icon = <i className="fa fa-check-circle"></i>;
          break;
        case 'Charging':
          icon = <i className="fa fa-bolt"></i>;
          break;
        case 'Checking':
          icon = <i className="fa-solid fa-clock"></i>;
          break;
        case 'Disconnected':
          console.log('Mic is disconnected', i);
          icon = <i className="fa fa-certificate"></i>;
          break;
        case 'Error':
          icon = <i className="fa fa-certificate"></i>;
          break;
        case 'Unknown':
        default:
          icon = <i className="fa fa-question-circle"></i>;
      }
      if (darkMode) {
        return <Popup key={i} trigger={icon} keepTooltipInside={true} className='darkmode-popup'>
          {slot_content}
        </Popup>
      }
      return <Popup key={i} trigger={icon} keepTooltipInside={true}>
        {slot_content}
      </Popup>
    });

    let index = 0;

    let micsExpected = (index === 0) &&
      `${number_mics_expected} mic${number_mics_expected === 1 ? '' : 's'} expected`;
    res.push(
      <div key={index} className="row box-contents">
        <div className="col-xs-2 center-xs">
          <p>Mics</p>
        </div>
        <div className="col-xs-5">
          {chargingIcons}
        </div>
        <div className="col-xs-5 center-xs">
          <p>{micsExpected}</p>
        </div>
      </div>
    );
  } else {
    //ERROR HANDLING
    if (!room.akg_connected) {
      res.push(
        <div key={-1} className="row box-contents">
          <div className="col-xs-2 center-xs">Mic</div>
          <div className="col-xs-10 center-xs">
            Disconnected from Mic Monitoring
          </div>
        </div>
      )
    } else if (!mic_data || !mic_data.Device || !mic_data.Slots) {
      res.push(
        <div key={-1} className="row box-contents">
          <div className="col-xs-2 center-xs">Mic</div>
          <div className="col-xs-10 center-xs">
            Data Error
          </div>
        </div>
      )
    }
  }

  return res;
}

function SourceIcon({ source, power }) {
  // If projector is off, don't display a source icon
  if (!power) {
    return null;
  }

  if (typeof source !== 'string') {
    return <i className="fa fa-question-circle" data-tooltip-id="tooltip" data-tooltip-content={source}></i>
  }
  let formattedSource = source.toLowerCase();
  if (formattedSource.includes("laptop") || formattedSource.includes("hdmi")) { //could be laptop 1 or 2
    return <i className="fa fa-laptop" data-tooltip-id="tooltip" data-tooltip-content={source}></i>
  } else if (formattedSource === "doc cam") {
    return <i className="fa-solid fa-square-pen" data-tooltip-id="tooltip" data-tooltip-content="Document Camera"></i>
  } else if (formattedSource.includes("imac") || formattedSource.includes("desktop") || formattedSource.includes("zoom") || formattedSource === 'pc') { //could be desktop 1 or 2
    return <i className="fa fa-desktop" data-tooltip-id="tooltip" data-tooltip-content={source}></i>
  } else if (formattedSource.includes("apple")) {
    return <i className="fa-brands fa-apple" style={{ fontSize: 17 }} data-tooltip-id="tooltip" data-tooltip-content={formattedSource.replace('apple tv', 'Apple TV')}></i>
  } else if (formattedSource.includes("roomassistant")) {
    return <i className="fa fa-tablet-screen-button" data-tooltip-id="tooltip" data-tooltip-content={source}></i>
  } else if (formattedSource.includes("student station")) {
    return <i className="fa fa-chalkboard-user" data-tooltip-id="tooltip" data-tooltip-content={source}></i>
  } else if (formattedSource.includes("atrium camera")) {
    return <i className="fa fa-camera" data-tooltip-id="tooltip" data-tooltip-content={source}></i>
  } else if (formattedSource.includes("atrium tv")) {
    return <i className="fa fa-person-chalkboard" data-tooltip-id="tooltip" data-tooltip-content={source}></i>
  }
  else if (formattedSource === "off") {
    return null;
  } else if (formattedSource.includes("no show") || formattedSource.includes("blank")) {
    return <i className="fa fa-eye-slash" data-tooltip-id="tooltip" data-tooltip-content={source} />;
  } else if (formattedSource === "") {
    return <i className="fa fa-question-circle" data-tooltip-id="tooltip" data-tooltip-content={"Unknown"} />;
  } else {
    return <i className="fa fa-question-circle" data-tooltip-id="tooltip" data-tooltip-content={source}></i>
  }
}


// A row with data for projector 1 or 2, as specified by its props
function ProjectorRows({ max_lamp_hours, room_id, json, location }) {
  var pds = json.physical_displays;
  var lds = json.logical_displays;
  const [openRow, setRow] = useState(false);

  if (pds == null || lds == null) {
    return <></>;
  }

  var pds_keys = Object.keys(pds);

  var projArr = Object.keys(lds).reduce((arrayL, key) => {
    let filteredProj = [];
    if (lds.hasOwnProperty(key)) {
      filteredProj = pds_keys.reduce((result, pkey) => {
        if (pds.hasOwnProperty(pkey)) {
          if (pds[pkey].logical_display === key) {
            result.push({
              healthy: pds[pkey].healthy,
              pname: pkey,
              lds: lds[key].name,
              hours: pds[pkey].hours,
              power: lds[key].is_on,
              source: lds[key].source_name
            });
          }
        }
        return result;
      }, []);

      if (filteredProj.length === 0) {
        filteredProj.push({
          healthy: true,
          pname: "none",
          lds: lds[key].name,
          hours: -1,
          power: lds[key].is_on,
          source: lds[key].source_name
        });
      }

      arrayL.push(filteredProj);
    }
    return arrayL;
  }, []);

  projArr = projArr.sort((a, b) => a[0]["lds"] > b[0]["lds"] ? 1 : -1);

  let render = "";
  if (location.pathname === "/") {
    render = projArr.map((pdisplays) => {
      let p = pdisplays.sort((a, b) => {
        return (!a.healthy && !b.healthy) ? 0 : (!b.healthy) ? -1 : 1;
      })[0];
      let progressClassName = "progress-bar";
      // max hours needs to be defined;
      let percent = (p.hours / max_lamp_hours) * 100;
      if (percent < 75) {
        progressClassName += " light-bluebg";
      } else if (percent < 90) {
        progressClassName += " yellowbg";
      } else {
        progressClassName += " redbg";
      }
      if (!p.healthy) {
        percent = 100;
        progressClassName = "progress-bar orangebg";
      }
      let display_percent = (percent > 100) ? 100 : (percent < 0) ? -1 : percent;
      let powerIcon = p.power ? (<i className="fa fa-circle green" data-tooltip-id="tooltip" data-tooltip-content="On"></i>) : (<i className="fa fa-square red" data-tooltip-id="tooltip" data-tooltip-content="Off"></i>);
      return (
        <div key={p.lds + " " + room_id} className="row box-contents">
          <div className="col-xs-4 rbox-source-name">
            {p.lds}
          </div>
          <div className="col-xs-2 center-xs">
            {powerIcon}
          </div>
          <div className="col-xs-2 center-xs">
            <SourceIcon source={p.source} power={p.power} />
          </div>
          <div className="col-xs-4 center-xs">
            {p.hours === -1 ? <></> : <div className="projector" data-tooltip-id="tooltip" data-tooltip-html={!p.healthy ? "" : p.hours + " hours used <br/ > Maximum: " + max_lamp_hours + " hours"}>
              <div className={progressClassName} style={{ width: display_percent + '%' }}></div>
              {!p.healthy ? <p className="projector-hours">Unhealthy</p> : p.hours === -1 ? <p className="projector-hours">none</p> : <p className="projector-hours">{p.hours} h</p>}
            </div>}
          </div>
        </div>
      )
    });
  } else {
    render = projArr.map((pdisplays) => {
      let s = pdisplays.sort((a, b) => {
        return (!a.healthy && !b.healthy) ? 0 : (!b.healthy) ? -1 : 1;
      });
      let p = s[0];
      let progressClassName = "progress-bar";
      // max hours needs to be defined;
      let percent = (p.hours / max_lamp_hours) * 100;
      if (percent < 75) {
        progressClassName += " light-bluebg";
      } else if (percent < 90) {
        progressClassName += " yellowbg";
      } else {
        progressClassName += " redbg";
      }
      if (!p.healthy) {
        percent = 100;
        progressClassName = "progress-bar orangebg";
      }

      let display_percent = (percent > 100) ? 100 : (percent < 0) ? -1 : percent;
      let powerIcon = p.power ? (<i className="fa fa-circle green" data-tooltip-id="tooltip" data-tooltip-content="On"></i>) : (<i className="fa fa-square red" data-tooltip-id="tooltip" data-tooltip-content="Off"></i>);

      if (!p.healthy) {
        percent = 100;
        progressClassName = "progress-bar orangebg";
      }
      return (
        <>
          {
            (pdisplays.length > 1 && "touchpanels" in json && p.healthy) ?
              (openRow) ?
                <>
                  <div key={p.lds + " " + room_id} className="row box-contents">
                    <div className="col-xs-2 center-xs rbox-source-name">
                      {p.pname}
                    </div>
                    <div className="col-xs-2 center-xs">
                      {powerIcon}
                    </div>
                    <div className="col-xs-2 center-xs">
                      <SourceIcon source={p.source} power={p.power} />
                    </div>
                    <div className="col-xs-5 center-xs">
                      <div className="projector" data-tooltip-id="tooltip" data-tooltip-html={p.hours + " hours used <br/ > Maximum: " + max_lamp_hours + " hours"}>
                        <div className={progressClassName} style={{ width: display_percent + '%' }}></div>
                        <p className="projector-hours">{p.hours} h</p>
                      </div>
                    </div>
                    <div className="col-xs-1 center-xs">
                      <i className="fa-solid fa-caret-up" onClick={() => { setRow(false) }}></i>
                    </div>
                  </div>
                  {
                    s.slice(1).map((pn) => {
                      let pcent = (pn.hours / max_lamp_hours) * 100;
                      let pcn = "progress-bar";
                      if (pcent < 75) {
                        pcn += " light-bluebg";
                      } else if (pcent < 90) {
                        pcn += " yellowbg";
                      } else {
                        pcn += " redbg";
                      }
                      let dp = (pcent > 100) ? 100 : (pcent < 0) ? -1 : pcent;
                      let pwrIcon = pn.power ? (<i className="fa fa-circle green" data-tooltip-id="tooltip" data-tooltip-content="On"></i>) : (<i className="fa fa-square red" data-tooltip-id="tooltip" data-tooltip-content="Off"></i>);
                      if (!pn.healthy) {
                        dp = 100;
                        pcn = "progress-bar orangebg";
                      }
                      return (
                        <>
                          <div key={pn.lds + " " + room_id} className="row box-contents">
                            <div className="col-xs-4 rbox-source-name">
                              {pn.pname}
                            </div>
                            <div className="col-xs-2 center-xs">
                              {pwrIcon}
                            </div>
                            <div className="col-xs-2 center-xs">
                              <SourceIcon source={pn.source} power={pn.power} />
                            </div>
                            <div className="col-xs-3 center-xs">
                              <div className="projector" data-tooltip-id="tooltip" data-tooltip-html={pn.healthy && pn.hours !== -1 ? pn.hours + " hours used <br/ > Maximum: " + max_lamp_hours + " hours" : null}>
                                <div className={pcn} style={{ width: dp + '%' }}></div>
                                {!pn.healthy ? <p className="projector-hours">Unhealthy</p> : pn.hours === -1 ? <p className="projector-hours">none</p> : <p className="projector-hours">{pn.hours} h</p>}
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    })
                  }
                </>
                :
                <>
                  <div key={p.lds + " " + room_id} className="row box-contents">
                    <div className="col-xs-4 rbox-source-name">
                      {p.lds}
                    </div>
                    <div className="col-xs-2 center-xs">
                      {powerIcon}
                    </div>
                    <div className="col-xs-2 center-xs">
                      <SourceIcon source={p.source} power={p.power} />
                    </div>
                    <div className="col-xs-3 center-xs">
                      <div className="projector" data-tooltip-id="tooltip" data-tooltip-html={p.hours + " hours used <br/ > Maximum: " + max_lamp_hours + " hours"}>
                        <div className={progressClassName} style={{ width: display_percent + '%' }}></div>
                        <p className="projector-hours">{p.hours} h</p>
                      </div>
                    </div>
                    <div className="col-xs-1 center-xs">
                      <i className="fa-solid fa-caret-down" onClick={() => { setRow(true) }}></i>
                    </div>
                  </div>
                </>
              :
              <>
                <div key={p.lds + " " + room_id} className="row box-contents">
                  <div className="col-xs-4 rbox-source-name">
                    {p.lds}
                  </div>
                  <div className="col-xs-2 center-xs">
                    {powerIcon}
                  </div>
                  <div className="col-xs-2 center-xs">
                    <SourceIcon source={p.source} power={p.power} />
                  </div>
                  <div className="col-xs-4 center-xs">
                    {p.healthy ? p.hours === -1 ? <></> :
                      <div className="projector" data-tooltip-id="tooltip" data-tooltip-html={p.hours + " hours used <br/ > Maximum: " + max_lamp_hours + " hours"}>
                        <div className={progressClassName} style={{ width: display_percent + '%' }}></div>
                        <p className="projector-hours">{p.hours} h</p>
                      </div> :
                      <div className="projector">
                        <div className={progressClassName} style={{ width: display_percent + '%' }}></div>
                        <p className="projector-hours">Unhealthy</p>
                      </div>}
                  </div>
                </div>
              </>
          }
        </>
      )
    });
  }
  return render;
}

// The camera livestream provided the imgSrc URL
function RoomCamera({ imgSrc }) {
  const [error, setError] = useState(false);
  const [loadedYet, setLoadedYet] = useState(false);
  const [prevImgSrc, setPrevImgSrc] = useState(imgSrc);
  useEffect(() => {
    if (imgSrc !== prevImgSrc) {
      if (loadedYet || prevImgSrc === 'none' || imgSrc === 'none') {
        setLoadedYet(false);
        setPrevImgSrc(imgSrc);
      }
    }
  }, [imgSrc, loadedYet, prevImgSrc]);

  function handleLoad() {
    setLoadedYet(true);
  }
  function handleError() {
    setLoadedYet(true);
    setError(true);
  }
  const imageStyle = {
    width: "100%",
    height: "100%",
    objectFit: "contain"
  }
  if (prevImgSrc === "none" || error) {
    // console.log("prev image:", prevImgSrc);
    // console.log("img src:", imgSrc);
    // console.log("error state:", error);
    // console.log("loaded yet:", loadedYet);
    return null;
  }
  else return (
    <div>
      <img src={prevImgSrc} style={imageStyle} alt="Could not load camera data" onLoad={handleLoad} onError={handleError}></img>
    </div>
  )
}

//A yellow, 'not connected' box with no relevant data displayed
function NotConnectedRoombox({ name, handleRedirect }) {
  return (
    <div className="box-container">
      <div className="room-box">
        {/* --TITLE-- */}
        <div className="row center-xs box-title yellowbg" onClick={handleRedirect}>
          <div className="col-xs-12 ">
            <h4>{name}</h4>
          </div>
        </div>
        <p style={{ textAlign: 'center', padding: 15 }}>
          ???
          <br />
          No Connection
        </p>
      </div>
    </div>
  )
}

//A box that displays room data, and wraps the above components
function Roombox({ room, darkMode, imgSrc }) {
  //get hooks
  const navigate = useNavigate();
  const location = useLocation();

  function handleRedirect() {
    navigate(`/room/${room.id}`);
  }


  if (room.type === "not_connected" || room.ping_status == null) {
    // can't communicate with crestron
    return <NotConnectedRoombox name={room.name} handleRedirect={handleRedirect} />
  } else {
    // we're connected
    let recordingIcon = null;
    // This is how the old CAENView does it - presumably, if leccap_recording is < 0, the room doesn't have one
    if (room.leccap_recording === 0) {
      recordingIcon = <i className="fas fa-video" style={{ color: "var(--box-title-bg)", pointerEvents: "auto" }} data-tooltip-id="tooltip" data-tooltip-content="Not Recording"></i>
    } else if (room.leccap_recording > 0) {
      recordingIcon = <i className="fas fa-video" style={{ color: "var(--dark-blue", pointerEvents: "auto"}} data-tooltip-id="tooltip" data-tooltip-content="Recording"></i>
    }

    //header background color
    let titleColor = "";

    switch (room.type) {
      case "conn": titleColor = "greybg"; break; // all good
      case "prob": titleColor = "redbg"; break; // problem button pressed, no one has responded
      case "av_issue": titleColor = "orangebg"; break; // AV subsystem issue (projector, audio)
      case "resp": titleColor = "greenbg"; break; // Problem button pressed, someone has responded
      default: console.error("Room number " + room.id + " has type " + room.type);
    }

    // if (!room.ping_status.health.healthy) {
    //   titleColor = "orangebg";
    // }

    return (
      <>
        <div className="box-container">
          <div className="room-box">

            {location.pathname.includes("/room") ?
              <>
                <div className={"row center-xs box-title around-xs " + titleColor}>
                  <div className="col-xs-12 room-box-title-container">
                    <div>
                      {recordingIcon}
                    </div>
                    <h4 style={(titleColor === " redbg" && !darkMode) ? { color: 'white' } : {}}>{room.name}</h4>
                    <div>
                      {
                        room.ping_status == null ?
                          <i className="fas fa-cog rc" position="right center" data-tooltip-id="tooltip" data-tooltip-content="Not Supported for this Room" style={{ color: "#8c8c8c", pointerEvents: 'auto' }} /> :
                          !('touchpanels' in room.ping_status) ?
                            <i className="fas fa-cog rc" position="right center" data-tooltip-id="tooltip" data-tooltip-content="Not Supported for this Room" style={{ color: "#8c8c8c", pointerEvents: 'auto' }} /> :
                            <RoomControl className="room_control_panel" titleColor={titleColor} roomid={room.id} />
                      }
                    </div>
                  </div>
                </div>
              </> :
              <div className={"row center-xs box-title around-xs " + titleColor} onClick={handleRedirect}>
                <div className="col-xs-12">
                  <h4 style={(titleColor === " redbg" && !darkMode) ? { color: 'white' } : {}}>{room.name}</h4>
                </div>
              </div>
            }
            <AVErrorRows room={room} darkMode={darkMode} />
            <RoomCamera imgSrc={imgSrc} />
            <MicRows room={room} darkMode={darkMode} />
            {
              <ProjectorRows max_lamp_hours={room.max_lamp_hours} room_id={room.id} json={room.ping_status} location={location} />
            }
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  if (state.dataReceived) {
    return {
      room: state.rooms[ownProps.roomid],
      darkMode: state.darkMode
    }
  }
}

export default connect(mapStateToProps)(Roombox)