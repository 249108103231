import React, { useEffect, useRef, useState } from 'react';
import ModernDatepicker from 'react-modern-datepicker';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import { Tooltip } from 'react-tooltip'

import 'rc-time-picker/assets/index.css'
import './ImageQueryStyles.css'
import ConfirmBox from '../Reusable/ConfirmBox';

// note: for other GRID_WIDTH and GRID_HEIGHT than 6 and 4, the grid spacing will be off.
const GRID_WIDTH = 6;
const GRID_HEIGHT = 4;

function GridSnap(props) {
	const { snap, jumpToSnapVideo } = props;
	const confirmBoxTargetRef = useRef(null);
	const [ confirmRedirect, setConfirmRedirect ] = useState(false);
	const [ imgLoaded, setImgLoaded ] = useState(false);

	return (
		<div className="col-xs-2 grid-snap-card">
			<img 
				className={imgLoaded ? "grid-snap-img fade-in" : "grid-snap-img"} 
				src={snap.url}
				onLoad={() => setImgLoaded(true)} 
				onClick={() => setConfirmRedirect(true)} 
				ref={confirmBoxTargetRef}
				alt=""
			/>
			{confirmRedirect && <ConfirmBox 
				targetEl={confirmBoxTargetRef && confirmBoxTargetRef.current}
				onCancel={() => setConfirmRedirect(false)}
				onConfirm={() => jumpToSnapVideo(snap)}
				buttonText="Video View"
			>
				<div className="col-xs-12 center-xs grid-snap-time-string">
					{moment(snap.time).format("MMMM D, YYYY h:mm a")}
				</div>
			</ConfirmBox>}
			{imgLoaded && <p className="center-xs fade-in">{moment(snap.time).format("h:mm a")}</p>}
		</div>
	)
}

function GridImageQuery(props) {
	const [ rooms, setRooms ] = useState([]);
	const [ selectedRoomName, setSelectedRoomName ] = useState("");
	const [ errorMsg, setErrorMsg ] = useState(null);
	const [ startDate, setStartDate ] = useState(moment().format("MMMM DD, YYYY"));
	const [ startTime, setStartTime ] = useState(moment(new Date()));
	const [ startTimepickerOpen, setStartTimepickerOpen ] = useState(false);
	const [ endDate, setEndDate ] = useState(moment().format("MMMM DD, YYYY"));
	const [ endTime, setEndTime ] = useState(moment(new Date()));
	const [ endTimepickerOpen, setEndTimepickerOpen ] = useState(false);
	const [ snaps, setSnaps ] = useState([]);
	const [ awaitingSnaps, setAwaitingSnaps ] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		console.log('grid view');
		window.g_socket.emit('get-image-roomlist');

		window.g_socket.on('image-roomlist', setRooms);

		window.g_socket.on('ip-image-urls-grid', (data) => {
			// data = { snapList: [ {time, filename} ... ] }
			setSnaps(data.snapList.map(snap => {
				// preload it
				const time = snap.time;
				const url = `https://s3.amazonaws.com/caenview-prod/${snap.filename}`;
				const filename = snap.filename;
				(new Image()).src = url;
				return { time, url, filename }
			}));
			setAwaitingSnaps(false);

			if (data.snapList.length === 0) {
				setErrorMsg("We couldn't find any snaps in that time range.");
			} else {
				setErrorMsg(null);
			}
		});

		return () => {
			// unsubscribe
			window.g_socket.removeAllListeners('ip-image-urls-grid');
			window.g_socket.removeAllListeners('image-roomlist');
		};
	}, []);

	const requestImages = () => {

		const start = new Date(startDate);
		const end = new Date(endDate);

		// add the hours/minutes time on
		start.setHours(start.getHours() + (new Date(startTime)).getHours());
		start.setMinutes(start.getMinutes() + (new Date(startTime)).getMinutes());
		end.setHours(end.getHours() + (new Date(endTime)).getHours());
    	end.setMinutes(end.getMinutes() + (new Date(endTime)).getMinutes());


		if (start.getTime() > end.getTime()) {
			setErrorMsg("Start time must be less than end time.");
			return;
		} else if (end.getTime() > Date.now()) {
			setErrorMsg("End time cannot be in the future.");
			return;
		}

		const roomId = rooms.find(room => (room.name === selectedRoomName)).id;

		setAwaitingSnaps(true);
		window.g_socket.emit('get-ip-image-urls-grid', {
			start,
			end,
			roomId,
			numSnaps: GRID_WIDTH * GRID_HEIGHT
		});
	}

	// for validating invalid dates and opening timepicker on change
	const dateChangedFunctor = (setDate, setTimepickerOpen) => {
		return (newDate) => {
			if (newDate !== "Invalid Date") setDate(newDate);
			setTimepickerOpen(true);
		}
	}

	const goToVideoView = () => {
		// the View change button's tooltip needs to know to hide
		navigate("/viewimages/video");
	}

	const jumpToSnapVideo = (snap) => {
		// use location state to tell the Video viewer which room / time to use
		navigate("/viewimages/video", {
			roomName: selectedRoomName,
			time: new Date(snap.time)
		});
	}

	let snapGrid = Array(GRID_HEIGHT);
	snapGrid = snapGrid.fill(null).map(() => Array(GRID_WIDTH));
	snaps.forEach((snap, index) => {
		const row = Math.floor(index / GRID_WIDTH);
		const col = index - row * GRID_WIDTH;
		snapGrid[row][col] = snap;
	});

	return (
		<div className="rooms-container grid-view">
			<div className="col between-xs">
				<div className="row" id="left-column-imagequery">
					<div className="imagequery-top-box">
						<h1>View Room Snaps</h1>
						<div>
							<input autoFocus className="text-input" type="text" list="imagequery-room-list" value={selectedRoomName} onChange={e => setSelectedRoomName(e.target.value)}/>
							<datalist id="imagequery-room-list" >
								{rooms.map( room => <option key={room.id} value={room.name} /> )}
							</datalist>
						</div>
					</div>
					<div className="imagequery-middle-box">
						<div className="date-time-pickers-container">
							<h2 className="to-from-box">From:&nbsp;</h2>
							<div className="datepicker">
								<ModernDatepicker
									date={startDate}
									onChange={dateChangedFunctor(setStartDate, setStartTimepickerOpen)}
									placeholder="Select a date..."
									format="MMMM DD, YYYY"
									primaryColor="var(--a-main-bg)"
									secondaryColor="var(--main-bg)"
									primaryTextColor="var(--info-text)"
									secondaryTextColor="var(--a-info-text)"
								/>
							</div>
							<div className="timepicker">
								<TimePicker
									value={startTime}
									onChange={setStartTime}
									open={startTimepickerOpen}
									onOpen={() => setStartTimepickerOpen(true)}
									onClose={() => setStartTimepickerOpen(false)}

									showSecond={false}
									format="h:mm a"
									use12Hours
									inputReadOnly
									onAmPmChange={() => setStartTimepickerOpen(false)}
									/>
							</div>
						</div>

						<div className="date-time-pickers-container">
							<h2 className="to-from-box">To:&nbsp;</h2>
							<div className="datepicker">
								<ModernDatepicker
									date={endDate}
									onChange={dateChangedFunctor(setEndDate, setEndTimepickerOpen)}
									placeholder="Select a date..."
									format="MMMM DD, YYYY"
									primaryColor="var(--a-main-bg)"
									secondaryColor="var(--main-bg)"
									primaryTextColor="var(--info-text)"
									secondaryTextColor="var(--a-info-text)"
								/>
							</div>
							<div className="timepicker">
								<TimePicker
									value={endTime}
									onChange={setEndTime}
									open={endTimepickerOpen}
									onOpen={() => setEndTimepickerOpen(true)}
									onClose={() => setEndTimepickerOpen(false)}

									showSecond={false}
									format="h:mm a"
									use12Hours
									inputReadOnly
									onAmPmChange={() => setEndTimepickerOpen(false)}
									/>
							</div>
						</div>

						{(startDate && startTime && endDate && endTime && rooms.some(room => (room.name === selectedRoomName)))
							&& 
							(awaitingSnaps 
								? <div className="button imagequery-go-button fade-in">
									<i className="fa fa-spinner fa-pulse fa-3x fa-fw" style={{ fontSize: '15px', marginTop:'0px' }}></i>
								  </div>
								: <div className="button imagequery-go-button fade-in" onClick={requestImages}>Go!</div>
							)}
						
						<div className="error-msg"><h2>{errorMsg}</h2></div>

					</div>

					<div className="imagequery-bottom-box">
						<h2>View:</h2>
						<div className="row view-container">
							<div
								className="button col-sm center-xs"
								onClick={goToVideoView}
								data-tooltip-id="tooltip"
								data-tooltip-content="View snaps as a video feed"
							><i className="fa fa-video"></i></div>
							&nbsp;
							<div
								className="button col-sm center-xs active-view-button" 
								data-tooltip-id="tooltip"
								data-tooltip-content="View snaps as a grid of snaps"
							><i className="fa fa-th"></i></div>
						</div>
					</div>

				</div>
				<div className="row" id="right-column">
					<div className="imagequery-grid">
						{snapGrid.map((row, i) => (
							<div className="row" key={i}>
								{row.map(snap => (
								  <GridSnap snap={snap} key={snap.filename} jumpToSnapVideo={jumpToSnapVideo} />
								))}
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}

export default GridImageQuery;