import React, { useRef, useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from '@fullcalendar/interaction';

import "./calendarCustomStyle.css"
import { connect } from 'react-redux'
import { setReduxState } from '../../helperFunctions/actions'

function Calendar(props) {
  // get state
  const calendarComponentRef = useRef(null);
  const [showFullHeight, setShowFullHeight] = useState(false);
  //initially only 'schedule' events are shown, like classes, leccaps, mtgs
  const [eventSources, setEventSources] = useState(props.apiURLs.events ? [{url: props.apiURLs.events}]:[]);
  const [showRoomScheduleEvents, setShowRoomScheduleEvents] = useState(true);
  const [showAVEvents, setShowAVEvents] = useState(false);
  const [showWixelEvents, setShowWixelEvents] = useState(false);

  function handleEventsSet(events) {
    // Make prof email list
    let emails = [];
    for(let i = 0; i < events.length; i++) {
      const { prof } = events[i].extendedProps;
      if(prof && !emails.includes(prof.uniqname + "@umich.edu")) {
        emails.push(prof.uniqname + "@umich.edu");
      }
    }

    props.setReduxState( {profEmails: emails} );

    let wixelWidth = 10; // percent
    // console.log('showWixelEvents:', showWixelEvents);
    let widthMinusWixel = showWixelEvents ? (100 - (props.chargingBasesInstalled * wixelWidth * 2)) : 100;
    //fill up that remaining room with columns of room schedule and projectors
    let columns = 0;
    if(showRoomScheduleEvents) {
      columns++;
    }
    if(showAVEvents) {
      columns += props.projectorsInstalled;
    }

    // set css variables for event positioning
    let root = document.documentElement;

    if(columns > 0) {
      let nonWixelEventWidth = (widthMinusWixel / columns);
      root.style.setProperty("--non-wixel-event-width", nonWixelEventWidth + '%');
      if(showRoomScheduleEvents) {
        root.style.setProperty("--proj1-left", nonWixelEventWidth + '%');
      } else {
        root.style.setProperty("--proj1-left", '0%');
      }
      if(showAVEvents) {
        root.style.setProperty("--num-projectors", props.projectorsInstalled);
      }
    } else {
      wixelWidth = 100/props.chargingBasesInstalled/2;
      widthMinusWixel = 0;
    }
    root.style.setProperty("--wixel-width", wixelWidth + '%');
    root.style.setProperty("--wixel-left", widthMinusWixel + '%');
    // console.log(widthMinusWixel);
  }

  function renderEventContent(info) { 
    //  info === { event, timeText, isStart isEnd isMirror isPast isFuture isToday }
    
    const { extendedProps } = info.event;
    if(extendedProps.wixel) {
      //mic, charging bases events are ---flat--- (no title, time)
      return (
        <div className="fc-event-main-frame">
        </div>
      )
    } else if (extendedProps.av) {
      //projectors, crestrons
      return (
        <div className="fc-event-main-frame" data-tooltip-id="tooltip" data-tooltip-content={info.event.title}>
          <div className="fc-event-time">{info.timeText}</div>
          <div className="fc-event-title-container">
            <div className="fc-event-title fc-sticky"> {info.event.title}</div>
          </div>
        </div>
      )
    } else {
      //room schedule
      const { prof } = extendedProps;
      let tooltipText = prof ? `${info.event.title}<br />Professor: ${prof.firstName} ${prof.lastName}` : info.event.title;

      return (
        <div className="fc-event-main-frame" data-tooltip-id="tooltip" data-tooltip-content={tooltipText}>
          <div className="fc-event-time">{info.timeText}</div>
          <div className="fc-event-title-container">
            <div className="fc-event-title fc-sticky">
              {info.event.title}
              {prof && <a className="prof-link" href={`mailto:${prof.uniqname}@umich.edu`}>{prof.firstName} {prof.lastName}</a>}
            </div>
          </div>
        </div>
      )
    }
  }

  function handleEventClassNames(info) {
    //SET THE WIDTH of multiple events
    const { extendedProps } = info.event;

    if (extendedProps.schedule) {
        return "fc-evt-classroom"
    } else if (extendedProps.wixel) {
      if (extendedProps.category === 'CB1' && extendedProps.subcategory === 'both') {
          // Charging base 1
          return "fc-evt-base1-both"
      }

      if (extendedProps.category === 'CB1' && extendedProps.subcategory === 'left') {
          // Charging base 1
          return "fc-evt-base1-left"
      }

      if (extendedProps.category === 'CB1' && extendedProps.subcategory === 'right') {
          // Charging base 1
          return "fc-evt-base1-right"
      }

      if (extendedProps.category === 'CB2' && extendedProps.subcategory === 'both') {
          // Charging base 2
          return "fc-evt-base2-both"
      }

      if (extendedProps.category === 'CB2' && extendedProps.subcategory === 'left') {
          // Charging base 2
          return "fc-evt-base2-left"
      }

      if (extendedProps.category === 'CB2' && extendedProps.subcategory === 'right') {
          // Charging base 2
          return "fc-evt-base2-right"
      }
  } else if (extendedProps.av) {
        // Crestrons should span the full width
        if (extendedProps.category === 'C') {
          return "fc-evt-crestron"
        }
        // Projector 1
        if (extendedProps.category === 'P' && extendedProps.subcategory === '1') {
          return "fc-evt-proj1"
        }
        // Projector 2
        if (extendedProps.category === 'P' && extendedProps.subcategory === '2') {
          return "fc-evt-proj2"
        }
    } else {
      console.error("Unrecognized event type: ", info.event);
      return "";
    }
  }

  // I think this will break.
  function handleEventDidMount(info) {
    if(info.event.extendedProps.wixel) {
      info.el.setAttribute("data-tooltip-content", info.event.title);
    }
    // Tooltip.rebuild();
  }

  function handleSourceChange(option) {
    let newSource = {};
    let addNewSource = false;
    if(option === 'av') {
      addNewSource = !showAVEvents;
      setShowAVEvents(!showAVEvents);
      newSource = {
        url: props.apiURLs.av,
        color: 'red'
      }
    } else if(option === 'wixel') {
      addNewSource = !showWixelEvents;
      setShowWixelEvents(!showWixelEvents);
      newSource = {
        url: props.apiURLs.wixel,
        color: 'orange'
      }
    } else if (option === 'room schedule') {
      addNewSource = !showRoomScheduleEvents;
      setShowRoomScheduleEvents(!showRoomScheduleEvents);
      newSource = {url: props.apiURLs.events};
    }
    if(addNewSource) {
      // add that URL
      setEventSources([...eventSources, newSource])
    } else {
      // filter out that URL
      setEventSources(eventSources.filter( source => (source.url !== newSource.url) ));
    }
  }

  function handleDateClick(info) {
    // info === { date, view, ...}
    const { date, view } = info;
    if(view.type === "timeGridDay") return; //do nothing
    if(view.type === "dayGridMonth") {
      //switch to date's week
      if(date.getDay() === 0 || date.getDay() === 6) {
        //weekend selected
        calendarComponentRef.current.getApi().changeView("timeGridWeek", date);
      } else {
        //weekday selected
        calendarComponentRef.current.getApi().changeView("timeGridWeekDay", date);
      }
    } else {
      //switch to date's day
      calendarComponentRef.current.getApi().changeView("timeGridDay", date);
    }
  }

  return (
    <div className="schedule">
      <div className="room-box wide-box">
        <div className="calendar-container">
          <FullCalendar 
            plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin ]}
            initialView="timeGridWeekDay"
            views={ {
              timeGridWeekDay: {
                type: 'timeGrid',
                duration: {
                  weeks: 1
                },
                buttonText: 'weekday',
                hiddenDays: [0, 6]
              }
            } }
            headerToolbar={ {
              left: 'dayGridMonth,timeGridWeek,timeGridWeekDay,timeGridDay',
              center: 'title',
              right: 'today prev,next'
            } }
            height={(showFullHeight) ? 'auto':700}
            scrollTime="08:00:00"
            nowIndicator={true}
            allDaySlot={false}

            eventSources={ [...eventSources] }
            eventContent={renderEventContent}
            eventDidMount={handleEventDidMount}
            dateClick={handleDateClick}
            eventsSet={handleEventsSet}
            eventClassNames={handleEventClassNames}
            eventDisplay={"block"}

            ref={calendarComponentRef}
          />
        </div>
        <div className="calendar-checkboxes">
          {props.apiURLs.events && <label className="list-group-item col-xs-3"><input type="checkbox" name="avToggle" checked={showRoomScheduleEvents} onChange={() => handleSourceChange('room schedule')} />
            <span className="checkmark"></span>
            <span className="list-item-name">Room Schedule</span>
          </label>}
          <label className="list-group-item col-xs-3"><input type="checkbox" name="avToggle" checked={showAVEvents} onChange={() => handleSourceChange('av')} />
            <span className="checkmark"></span>
            <span className="list-item-name">AV Events</span>
          </label>
          <label className="list-group-item col-xs-3"><input type="checkbox" name="avToggle" checked={showWixelEvents} onChange={() => handleSourceChange('wixel')} />
            <span className="checkmark"></span>
            <span className="list-item-name">Mic Events</span>
          </label>
          <label className="list-group-item col-xs-3"><input type="checkbox" name="avToggle" checked={showFullHeight} onChange={() => setShowFullHeight(prev => !prev)} />
            <span className="checkmark"></span>
            <span className="list-item-name">Show Full View</span>
          </label>
        </div> 

      </div>
    </div>
  )
}

const mapDispatchToActions = { setReduxState };

export default connect(null, mapDispatchToActions)(Calendar)