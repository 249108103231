import { filter, getRoomTypes, getRoomStatus, formatRoomsArray, getDisplayedRoomTypes, getDisplayedRoomStatus } from "./filter"
import Cookies from 'universal-cookie'

const cookies = new Cookies(null, {path:'/'});
//INITIAL REDUX STORE STATE VALUE
const initState = {
  isLoggedIn: false,
  uniqname: null,
  nickname: null,
  errorObj: {message:''},
  // [0]: room status, [1]: room type, [2]: building
  activeFilters: ["All Rooms", ["CoE Classroom", "CAEN Lab", ""], "All Buildings"],
  buildings: [], //alphabetically sorted
  rooms: {}, // key: roomid, value: room data object
  roomTypes: {
    CoEClassroom: [],  //e.g. the room ids that are CoE classrooms
    CAENLab: [], 
    DeptClassroom: []
  },
  displayRoomTypes: {
    CoEClassroom: 0,  //i.e. the number of rooms of each type currently showing
    CAENLab: 0, 
    DeptClassroom: 0
  },
  roomStatus: {
    AllRooms: [],
    NeedsAttention: [], //e.g. the room ids that need attn.
    ProjectorsOn: [],
    MicsNotCharged: [],
    CurrentlyRecording: []
  },
  displayRoomStatus: {
    AllRooms: 0,
    NeedsAttention: 0, //e.g. the # of rooms that need attn.
    ProjectorsOn: 0,
    MicsNotCharged: 0,
    CurrentlyRecording: 0
  },
  intersectedRooms: [], //the rooms that fit the selected roomTypes and roomStatus and building
  searchQuery: "",
  dataReceived: false, //have we the room data yet?
  darkMode: false, // false means lightmode
  soundOn: false, // sounds is off. toggled by topbar
  showImages: false, // images start off without being shown
  showBuildings: true,
  refreshImages: true, //by default, refresh images every 15 seconds
  spinRefreshIcon: false, //don't spin the icon yet
  problemRooms: [],
  ignoreNoticeBar: false,
  profEmails: [],
  modal: { shown: false },
  canViewImagequery: false,
  testMode: null,
  lastModifiedDate: null,
  mode: null,
  weatherMonitoringEndpoint: null
}
const millisecondsInADecade = 31556952000 * 10; //options should persist across sessions
const COOKIE_OPTIONS = { path: '/', expires: new Date(Date.now() + millisecondsInADecade) }

if(cookies.get('darkMode')) {
  //if we have one cookie, we should have them all. Set init redux store values.
  initState.darkMode = (cookies.get('darkMode') === true)
  initState.soundOn = (cookies.get('soundOn') === true)
  initState.showImages = (cookies.get('showImages') === true)
  initState.showBuildings = (cookies.get('showBuildings') === true)
  initState.refreshImages = (cookies.get('refreshImages') === true)
  if(cookies.get('roomTypes')) initState.activeFilters[1] = cookies.get('roomTypes');
} else {
  //set all those cookies
  cookies.set('darkMode', 'false', COOKIE_OPTIONS)
  cookies.set('soundOn', 'false', COOKIE_OPTIONS)
  cookies.set('showImages', 'false', COOKIE_OPTIONS)
  cookies.set('showBuildings', 'true', COOKIE_OPTIONS)
  cookies.set('refreshImages', 'true', COOKIE_OPTIONS)
  cookies.set('roomTypes', initState.activeFilters[1], COOKIE_OPTIONS); // ['CoE Classroom' 'CAEN Lab' ""]
}

function switchTheme(darkMode) {
  let root = document.documentElement;
  if(darkMode) {
    /* DARK */
    root.style.setProperty("--main-bg", "#121212");

    root.style.setProperty("--text-input-bg", "#383838");
    root.style.setProperty("--a-text-input-bg", "#414141");
    root.style.setProperty("--info-text", "#E1E1E1");
    root.style.setProperty("--a-info-text", "#FFFFFF");
    root.style.setProperty("--a-text-input-border", "#EFEFEF");
    
    root.style.setProperty("--button-bg", "#383838");
    root.style.setProperty("--a-button-bg", "#383838");
    
    root.style.setProperty("--header-color", "#EFEFEF");
    
    /* TOPBAR */
    root.style.setProperty("--tbar-bg", "#272727");
    root.style.setProperty("--tbar-dropdown-title", "#EFEFEF");

    /* row items */
    root.style.setProperty("--a-main-bg", "#202020");

    /* Roombox and sidebar titles */
    root.style.setProperty("--box-title-bg", "#272727");

    /* Dividers */
    root.style.setProperty("--thick-divider", "#676767");
    root.style.setProperty("--thin-divider", "#565656");

    /* ROOM NUMBER BADGES */
    root.style.setProperty("--a-box-title-bg", "#676767");

    /* BG COLORS */
    root.style.setProperty("--light-blue", "#5C8C9E"); /* Projector hours, currently viewing, calendar */
    root.style.setProperty("--dark-blue", "#5D8AE6");/* Links, Current recording indicator, checkbox/radio */
    root.style.setProperty("--green", "#3C8C51");/* Color key, Projector 'on' indicator */
    root.style.setProperty("--yellow", "#9E8F2D"); /* Color key, Projector hours bar */
    root.style.setProperty("--orange", "#AD6230");/* Color key */
    root.style.setProperty("--red", "#C44B4B");/* Color key, Projector hours, Needs attention */

    root.style.setProperty("--box-hover-brightness", "115%");

    root.style.setProperty("--text-area-bg", "#383838");
    
  } else {
    /* LIGHT */
    root.style.setProperty("--main-bg", "#FFFFFF");

    root.style.setProperty("--text-input-bg", "#F2F2F2");
    root.style.setProperty("--a-text-input-bg", "#FFFFFF");
    root.style.setProperty("--info-text", "#444444");
    root.style.setProperty("--a-info-text", "#2A2A2A");
    root.style.setProperty("--a-text-input-border", "#707070");
    
    root.style.setProperty("--button-bg", "#E9E9E9");
    root.style.setProperty("--a-button-bg", "#C4C4C4");
    
    root.style.setProperty("--header-color", "#2A2A2A");
    
    /* TOPBAR */
    root.style.setProperty("--tbar-bg", "#2A2A2A");
    root.style.setProperty("--tbar-dropdown-title", "#FFFFFF");

    /* row items */
    root.style.setProperty("--a-main-bg", "#F2F2F2");

    /* Roombox and sidebar titles */
    root.style.setProperty("--box-title-bg", "#DEDEDE");

    /* Dividers */
    root.style.setProperty("--thick-divider", "#707070");
    root.style.setProperty("--thin-divider", "#ACACAC");

    /* ROOM NUMBER BADGES */
    root.style.setProperty("--a-box-title-bg", "#444444");

    /* BG COLORS */
    root.style.setProperty("--light-blue", "#95E2FF"); /* Projector hours, currently viewing, calendar */
    root.style.setProperty("--dark-blue", "#6596F9");/* Links, Current recording indicator, checkbox/radio */
    root.style.setProperty("--green", "#60E182");/* Color key, Projector 'on' indicator */
    root.style.setProperty("--yellow", "#FFE74A"); /* Color key, Projector hours bar */
    root.style.setProperty("--orange", "#FF9046");/* Color key */
    root.style.setProperty("--red", "#FF6868");/* Color key, Projector hours, Needs attention */
    
    root.style.setProperty("--box-hover-brightness", "87%");
  }
}
// ------------ SET INIT THEME ------------
switchTheme(initState.darkMode);

export default function rootReducer (state = initState, action) {
  // console.log('action: ', action);
  switch(action.type) {
    case 'UPDATE_ROOM':
      //update a single room's information
      // called from socketManager.js in socket.on('status', ()=> {...}}
      const { rooms } = state;
      if(JSON.stringify(rooms[action.payload.id]) !== JSON.stringify(action.payload)) {
         //replace item [id] of rooms with new data
        return {
          ...state,
          rooms: {
            ...rooms,
            [action.payload.id]: action.payload
          }
        }
      } else {
      return state;
      }

    case 'UPDATE_SHOWN_ROOMS':
      //update the rooms shown on the screen at the moment
      const intersectedRooms = filter(state);
      if(JSON.stringify(intersectedRooms) !== JSON.stringify(state.intersectedRooms)) {
        return {
          ...state,
          intersectedRooms,
          displayRoomTypes: getDisplayedRoomTypes({...state, intersectedRooms}),
          displayRoomStatus: getDisplayedRoomStatus(state.roomStatus, state.roomTypes, state.activeFilters)
        }
      } else {
        return {
          ...state,
          displayRoomTypes: getDisplayedRoomTypes({...state, intersectedRooms}),
          displayRoomStatus: getDisplayedRoomStatus(state.roomStatus, state.roomTypes, state.activeFilters)
        }
      }


    case 'SERVER_DIED':
      return {
        ...state,
        modal: {
          shown: true,
          header: "The Server is Down",
          paragraph: "Amazon Web Services will restart it in a minute or so. Check back then for new data.",
          buttonText: "Reload",
          onClose: () => window.location.reload()
        }
      }


    case 'SET_DATA':
      // console.log("H?")
      // console.log({...state,...action.payload});
      return {
        ...state,
        ...action.payload
      }


    case 'UPDATE_ROOM_STATUS':
      const roomStatus = getRoomStatus(state.rooms);
      const displayRoomStatus = getDisplayedRoomStatus(roomStatus, state.roomTypes, state.activeFilters);
      if(JSON.stringify(roomStatus) !== JSON.stringify(state.roomStatus)) {
        return {
          ...state,
          displayRoomStatus,
          roomStatus
        }
      } else {
        return state;
      }

    case 'UPDATE_ROOM_TYPES':
      return {
        ...state,
        roomTypes: getRoomTypes(state.rooms)
      }


    case 'ADD_ALL_ROOMS':
      return {
        ...state,
        ...formatRoomsArray(action.payload)
      }


    case 'UPDATE_ACTIVE_FILTERS':
      //set cookie for room type
      cookies.set('roomTypes', action.payload[1], COOKIE_OPTIONS);
      return {
        ...state, 
        activeFilters: action.payload
      }


    case 'TOGGLE_COOKIE':
      // toggles on and off the cookie named by action.payload
      // doesn't actually modify the redux store (at the moment) but it's more centralized in data
      if(cookies.get(action.payload) === true) {
        cookies.set(action.payload, 'false', COOKIE_OPTIONS)
      } else {
        cookies.set(action.payload, 'true', COOKIE_OPTIONS)
      }
      if(action.payload === "darkMode") {
        switchTheme(cookies.get("darkMode") === true)
      }
      if(state.hasOwnProperty(action.payload)) {
        //if it has this cookie as a property, toggle that property
        return {
          ...state,
          [action.payload]: (cookies.get(action.payload) === true)
        }
      }
      return state;

    default:
      return state
  }
}