import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../helperFunctions/withRouter';
import { setModal } from '../../helperFunctions/actions';
import { Navigate } from 'react-router-dom';
import Roombox from '../RoomMenu/Roombox';
import Projectors from './Projectors';
import LectureCapture from './LectureCapture';
import RoomDetails from './RoomDetails';
import LiveRoomCamera from './LiveRoomCamera';
import IOSScreenshot from './IOSScreenshot';
import UserProblem from './UserProblem';
import IOSRoomControl from './IOSRoomControl';
import Roomlog from './Roomlog';
import Calendar from './Calendar';
import RoomNotes from './RoomNotes';

// todo 
// disconnect is in wrong place

const RoomInfo = (props) => {
  const [JSONreceived, setJSONreceived] = useState(false);
  const [viewingUsers, setViewingUsers] = useState([]);
  const [roomData, setRoomData] = useState({});
  const [calendarURLs, setCalendarURLs] = useState({});
  const dataReady = useRef(false);

  useEffect(() => {
    dataReady.current = JSONreceived;
  }, [JSONreceived]);

  const requestDetails = (tries) => {
    if (!dataReady.current) {
      if (tries > 0) {
        window.g_socket.emit('details', props.room.id);
        setTimeout(() => requestDetails(tries - 1), 500);
      } else {
        props.setModal({
          shown: true,
          header: "There's been a disconnect.",
          paragraph: "We can't connect to this room right now - try reloading the page.",
          buttonText: "Reload",
          onClose: () => window.location.reload()
        });
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    requestDetails(5); // try 5 socket emits

    window.g_socket.on('users', (data) => {
      setViewingUsers(data);
    });

    window.g_socket.on('detailsJSON', (room) => {
      let baseUrl = '';
      if (window.location.href.indexOf(':3000') > 0) {
        baseUrl = 'https://itech-test.engin.umich.edu:8443';
      }

      setRoomData({
        webcam: room.webcam,
        prob_note: room.prob_note,
        leccap: room.leccap,
        ping_status: room.ping_status,
        projectorsInstalled: room.projectorsInstalled,
        chargingBasesInstalled: room.chargingBasesInstalled,
        room_notes: room.room_notes,
      });

      setCalendarURLs({
        events: baseUrl + room.eventURL,
        av: baseUrl + '/api/avevents/' + room.id, // AV events in a room - projectors and crestrons
        wixel: baseUrl + '/api/wixelevents/' + room.id // Wixel events in a room - microphones
      });

      setJSONreceived(true); // let the components know they can render, stop asking the server for data
      window.scrollTo(0, 0); // prevent the page from shunting the user to the bottom
    });

    return () => {
      window.g_socket.removeAllListeners('users');
      window.g_socket.removeAllListeners('detailsJSON');
      window.g_socket.emit('leaving-room', props.room.id); // let the server know we're leaving the room
    };
  }, [props.room.id]);

  if (!JSONreceived) return <i className="fa fa-spinner fa-pulse"></i>;

  const { room } = props;
  const { webcam, leccap, projectorsInstalled, chargingBasesInstalled, room_notes } = roomData;

  return (
    <div className="roominfo-container">
      <div className="row center-xs room-header">
        <h1 className="room-name">{room.name}</h1>
        <div className="viewing-users">
          <p className="viewing-label">Viewing this room:</p>
          {viewingUsers.map((uniqname, i) => <p className="user-label" key={i}>{uniqname}</p>)}
        </div>
      </div>

      <div className="room-elements">
        <div className="row between-xs">
          <div className="col-xs-3" id="left-column">
            <LiveRoomCamera room={room} webcam={webcam} />
            {room.screenshot && <IOSScreenshot room={room} screenshot={room.screenshot} />}
            <Roombox roomid={room.id} imgSrc="none" />
            { room.user_problem > 0 && <UserProblem room={room} />}
            <Projectors room={room} projectorsInstalled={projectorsInstalled} />
            {room.display_data && <IOSRoomControl display_data={room.display_data} sources={room.sources} room={room} />}
            <LectureCapture leccapData={leccap} />
            <RoomDetails />
            <RoomNotes roomid={room.id} room_notes={room_notes} />
          </div>

          <div className="col-xs-9" id="right-column">
            <div className="weather-graph"></div>
            <Calendar apiURLs={calendarURLs} chargingBasesInstalled={chargingBasesInstalled} projectorsInstalled={projectorsInstalled} />
            <Roomlog room={room} />
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  if (state.dataReceived) {
    return {
      room: state.rooms[ownProps.roomid]
    };
  }
  return {};
}

const mapDispatchToActions = { setModal };

export default withRouter(connect(mapStateToProps, mapDispatchToActions)(RoomInfo));