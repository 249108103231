import React from 'react'
import { connect } from 'react-redux'

function MenuKey(props) {
  return (
    <div className="key fade-in">
      <div className="row center-xs dropdown-title">
          <div className="col-xs-5 center-xs">
              <h4>Color Key</h4>
          </div>
          <div className="col-xs-4 center-xs">
              <h4>Microphone Key</h4>
          </div>
          <div className="col-xs-3 center-xs">
              <h4>Projector Key</h4>
          </div>
      </div>
      <div className="row dropdown-contents">
          <div className="col-xs-5 center-xs">
              <div className="color-key greybg">OK</div>
          </div>
          <div className="col-xs-4">
              <i className="fa fa-circle"></i>Empty
          </div>
          <div className="col-xs-3">
              <i className="fa fa-circle green"></i>On
          </div>
      </div>
      <div className="row dropdown-contents">
          <div className="col-xs-5 center-xs">
              <div className="color-key greenbg">Responding to Problem</div>
          </div>
          <div className="col-xs-4">
              <i className="fa fa-check-circle"></i>Charged
          </div>
          <div className="col-xs-3">
              <i className="fa fa-square red"></i>Off
          </div>
      </div>
      <div className="row dropdown-contents">
          <div className="col-xs-5 center-xs">
              <div className="color-key yellowbg">Connection Error</div>
          </div>
          <div className="col-xs-4">
              <i className="fa fa-bolt"></i>Charging
          </div>
          <div className="col-xs-3">
              <i className="fa fa-laptop"></i>Laptop
          </div>
      </div>
      <div className="row dropdown-contents">
          <div className="col-xs-5 center-xs">
              <div className="color-key orangebg">AV Error</div>
          </div>
          <div className="col-xs-4">
              <i className="fa-solid fa-clock"></i>Checking
          </div>
          <div className="col-xs-3">
              <i className="fa fa-desktop"></i>Desktop
          </div>
      </div>
      <div className="row dropdown-contents">
          <div className="col-xs-5 center-xs">
              <div className="color-key redbg" style={!props.darkMode ? {color: 'white'}:{}}>Problem Reported</div>
          </div>
          <div className="col-xs-4">
              <i className="fa fa-certificate"></i>Error
          </div>
          <div className="col-xs-3">
              <i className="fa-solid fa-square-pen"></i>Doc Cam
          </div>
      </div>
      <div className="row dropdown-contents">
          <div className="col-xs-5 center-xs">
          
          </div>
          <div className="col-xs-4">
              <i className="fa fa-times-circle"></i>Disconnected
          </div>
          <div className="col-xs-3">
          <i className="fa-brands fa-apple" style={{fontSize:17}} data-tooltip-id="tooltip" data-tooltip-content="Apple TV"></i>Apple TV
          </div>
      </div>
      <div className="row dropdown-contents">
          <div className="col-xs-5 center-xs">
          </div>
          <div className="col-xs-4">
              <i className="fa fa-question-circle"></i>Unknown
          </div>
          <div className="col-xs-3">
              <i className="fa fa-eye-slash"></i>Blank
          </div>
      </div>
      <div className="row dropdown-contents">
          <div className="col-xs-5 center-xs">
          </div>
          <div className="col-xs-4">
          </div>
          <div className="col-xs-3">
              <i className="fa fa-tablet-screen-button"></i>Room Assistant
          </div>
      </div>
      <div className="row dropdown-contents">
          <div className="col-xs-5 center-xs">
          </div>
          <div className="col-xs-4">
          </div>
          <div className="col-xs-3">
              <i className="fa fa-chalkboard-user"></i>Student Station
          </div>
      </div>
      <div className="row dropdown-contents">
          <div className="col-xs-5 center-xs">
          </div>
          <div className="col-xs-4">
          </div>
          <div className="col-xs-3">
              <i className="fa fa-camera"></i>Atrium Camera
          </div>
      </div>
      <div className="row dropdown-contents">
          <div className="col-xs-5 center-xs">
          </div>
          <div className="col-xs-4">
          </div>
          <div className="col-xs-3">
              <i className="fa fa-person-chalkboard"></i>Atrium TV
          </div>
      </div>
      <div className="row dropdown-contents">
          <div className="col-xs-5 center-xs">
          </div>
          <div className="col-xs-4">
          </div>
          <div className="col-xs-3">
              <i className="fa fa-question-circle"></i>Unknown
          </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ( {darkMode:state.darkMode} );
export default connect(mapStateToProps)(MenuKey)